import React, {useEffect, useContext} from 'react';
import { FabContext } from "../../context/FabContext";
import Layout from "../../components/layout"
import Slider from "react-slick";
import '../slick.css'
import './style.scss';

const Experiences = () => {

    const{shoot,setLocal} = useContext(FabContext);
    let expSlider = null;
    // function next() { bridesSlider.slickNext()}
    // function previous() { bridesSlider.slickPrev()}

    // function logit() {
    //     if(window.pageYOffset > scrollY){ next(); }
    //     else{ previous(); }
    //     setScrollY(window.pageYOffset);
    // }

    useEffect(()=>{ setLocal('exp'); },[]);


    const settings = {
        speed: 9000,
        autoplay: true,
        autoplaySpeed: 0,
        cssEase: 'linear',
        infinite: true,
        variableWidth: true,
        centerMode:true,
        slidesToShow:3,
        slidesToScroll:2,
        useTransform: false,
        dots:false,
        arrows:false,
    };

    const scrollable = shoot.map((pic,index)=> <img key={index} alt="intro tile" className="experiences__intro__pictures__tile" src={`../images/experiences/scroll/${pic}`} />)

    return(
        <Layout>
        <div  
        className="experiences">
            <div className="experiences__intro">
                <div  className="experiences__intro__text">
                    <h1 className="experiences__intro__text__headline">It's all about<br/>the Experience</h1>
                    <p className="experiences__intro__text__copy">Picture this: it’s your wedding day and you are standing in your dream gown.  You are about to marry the love of your life, and are feeling like the most fabulous woman in the world.  This is the FAB Styling experience; to provide the best possible service tailored to each bride so she can feel picture-perfect on her wedding day. </p>
                </div>
                <div className="experiences__intro__rectangle" />
                <Slider ref={c => (expSlider = c)} className="experiences__intro__pictures"  {...settings} >{scrollable}</Slider>
                <p className="experiences__intro__attribution">ALL IMAGERY<br/>&copy; Jamie Levine Photography</p>
            </div>
            <div className="experiences__shopping">
                <div className="experiences__shopping__left">
                    <h5 className="experiences__shopping__left__flag">SHOPPING</h5>
                    <h3 className="experiences__shopping__left__headline">Let's get<br/>shopping!</h3>
                    <p className="experiences__shopping__left__copy">It is important for me to get to know my brides’ style so I can help them find exactly what they are looking for! Starting off with a consultation about your wedding and gown ideas, careful research is done to find the right designers and salons based on your style, silhouette and price point. Once these looks are reviewed together, it’s time to shop! Appointments will be booked according to what’s best for your schedule and certain styles will be pulled ahead of time to ensure we get to find exactly what you’re looking for! Using my strong connections in the industry with different bridal salons and designers, the Shopping Experience provides my clients with a unique, behind-the-scenes experience so that they can find their ultimate dream gown!</p>
                </div>
                <div className="experiences__shopping__right">
                    <div className="experiences__shopping__right__small"/>
                    <div className="experiences__shopping__right__large"/>
                </div>
            </div>
            <div className="experiences__wedding">
                <div className="experiences__wedding__blue" />
                <div className="experiences__wedding__lg" />
                <div className="experiences__wedding__sm" />
                <div className="experiences__wedding__text">
                    <h5 className="experiences__wedding__text__flag">WEDDING DAY</h5>
                    <h3 className="experiences__wedding__text__headline">First comes the<br/>gown, then comes<br/>the marriage!  </h3>
                    <p className="experiences__wedding__text__copy">Now that you’ve found your dream dress, it’s time to start your dream day. Brides get pulled in so many directions on their wedding day, wouldn’t it be great to have a personal right-hand (wo)man helping you along the way? The wedding day service is to tend to the bride’s every need when it comes to her bridal gown and everything that goes with it. Ensuring your gown is ready to be slipped on before the first look, making sure you look perfect in every photograph, helping with your bustle after the ceremony and so much more. My goal is to make this day as easy as possible for you by giving you one less thing to worry about; yourself! I will travel far and wide to get to your wedding. Whether it’s right here in NYC or out in LA, I am ready to go! </p>
                </div>
            </div>
            <div className="experiences__ultimate">
                <div className="experiences__ultimate__left" />
                <div className="experiences__ultimate__right" />
                <div className="experiences__ultimate__text">
                    <h5 className="experiences__ultimate__text__flag">ULTIMATE</h5>
                    <h3 className="experiences__ultimate__text__headline">From dress shopping to wedding day needs, it is my mission to ensure you are taken care of.</h3>
                    <p className="experiences__ultimate__text__copy">Being a part of your wedding day from start to finish is truly a gift to me! I love to see how my brides grow through their Shopping Experience and discover their bridal style and see first hand how fabulous they look and feel on their wedding day.  A combination of both of my services, the Ultimate Experience makes sure that you are happy from our first consultation, all the way through to the last dance on your wedding night.</p>
                </div>
            </div>
            <div  className="experiences__additional">
                <div className="experiences__additional__center">
                <div className="experiences__additional__center__intro">
                    <h3 className="experiences__additional__center__intro__headline" >Additional<br/>Experiences</h3>
                    <p className="experiences__additional__center__intro__copy" >We offer a variety of other experiences tailored to each bride, here are some examples</p>
                </div>
                <ul className="experiences__additional__center__list">
                    <li className="experiences__additional__center__list__item">
                        <h4 className="experiences__additional__center__list__item__headline">Mother of the <br/>bride experience </h4>
                        <p className="experiences__additional__center__list__item__copy">Not only do I want my brides to look and feel their best on their wedding day, but my moms too! This service can be used for the mother of the bride, mother of the groom or even if you want to give your maid of honor the VIP treatment</p>
                    </li>
                    <li className="experiences__additional__center__list__item">
                        <h4 className="experiences__additional__center__list__item__headline">Bridesmaid<br/>experience</h4>
                        <p className="experiences__additional__center__list__item__copy">I love a good girl gang. And where would you be without your trusted bridesmaids? Let me help your bridesmaids look and feel their ultimate best (without upstaging the bride of course!)</p>
                    </li>
                    <li className="experiences__additional__center__list__item">
                        <h4 className="experiences__additional__center__list__item__headline">Fittings<br/>experience</h4>
                        <p className="experiences__additional__center__list__item__copy">What do you do now that you’ve picked the perfect gown? I can help you find the right undergarments, accessories, shoes as more!  I can also schedule and attend all fitting appointments with you so we can get to know you and your gown a little better!  An overskirt that needs extra attention? I’ve got it!</p>
                    </li>
                </ul>
                </div>
            </div>
        </div>
        </Layout>
    )
}
export default Experiences;
